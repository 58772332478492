/* You can add global styles to this file, and also import other style files */
@import "primeng/resources/themes/bootstrap4-light-blue/theme.css";
@import "primeng/resources/primeng.css";
@import "primeicons/primeicons.css";
* {
  margin: 0px;
  padding: 0px;
  font-family: "Inter";
//   box-shadow: ;
}

body {
  height: 100vh;
  font-size: 14px;
}

.alert {
  margin-left: 11.2px;
}